import React from 'react';
import { isDesktop as checkDesktop, useWindowDimensions } from 'shared/hooks/useWindowDimensions';
import HeaderStyles from 'shared/components/Header/styles';
import MenuIcon from 'assets/images/icons/menu-icon.svg';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';
import { EModals, showModal } from 'store/slices/modals';

import Button from 'shared/components/Buttons';
import NavigationList from 'shared/components/NavigationList';
import { EButtonActions } from 'shared/enums';
import UserAvatar from 'shared/components/UserAvatar';
import { connectButtonStyles } from 'shared/components/Header/constants';

import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';

import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import Logo from './components/Logo';

import NetworkHandler from './components/NetworkHandler';

const Header: React.FC = () => {
  const dimension = useWindowDimensions();
  const isDesktop = checkDesktop(dimension);
  const dispatch = useAppDispatch();
  const { open } = useWeb3Modal();

  const { address, isConnected } = useAccount();

  const connectWalletHandler = async () => {
    try {
      MixpanelService.trackEvent(EMixpanelEvents.CONNECT_WALLET_CLICKED);
      open();
    } catch (e) {
      console.warn(e);
    }
  };

  const authHandler = (err:any, data:any) => {
    console.log(err, data);
  };

  return (
    <HeaderStyles.Wrapper>
      <HeaderStyles.FlexRow>
        {!isDesktop && (
          <HeaderStyles.MenuIcon
            src={MenuIcon}
            alt="logo"
            onClick={() => dispatch(showModal({ modal: EModals.MOBILE_MENU, props: {} }))}
          />
        )}
        <Logo />
      </HeaderStyles.FlexRow>
      <HeaderStyles.FlexRow>
        {isDesktop && <NavigationList />}
        {/* {isConnected && (
          <Button
            label="Create Report"
            handleClick={createReport}
            variant={EButtonActions.RED_BUTTON}
            styles={reportButtonStyles}
          />
        )} */}
        <NetworkHandler />
        {!isConnected && (
          <Button
            label="Connect Wallet"
            handleClick={connectWalletHandler}
            variant={EButtonActions.GREY_BUTTON}
            styles={connectButtonStyles}
          />
        )}
        {isConnected && <UserAvatar nickname={address} isHunter={false} />}
      </HeaderStyles.FlexRow>
    </HeaderStyles.Wrapper>
  );
};

export default Header;
